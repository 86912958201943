<template>
  <div class="root mt-5 text-center px-5">
    <NotificationFlowModal
        :dialogClass="dialogClass"
        :dialogHeading="dialogHeading"
        :dialogIconSrc="dialogIconSrc"
        :dialogInstruction="dialogInstruction"
        :dialogActionText="dialogActionText"
        :formError="formError()"
        :formErrorMessage="formErrorMessage()"
        @dialogAction="dialogAction"
        @handleBackToPortalLogin="handleBackToPortalLogin"
        ref="notificationFlowModal"
    />
  </div>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import ClickToCallMain from "../components/ClickToCallMain";
  import NotificationFlowModal from "@/components/NotificationFlowModal";

  export default {
    data: function() {
      return {
        dialog: null,
        type: null,
        dialogClass: null,
        dialogIconSrc: null,
        dialogHeading: "Next Step:",
        dialogInstruction: null,
        dialogActionText: null,
        dialogActionHref: "#",
        dialogAction: this.closeModal,
        success: false,
        intendedRoute: null,
        responseAuthStatus: null
      }
    },
    name: 'Landing',
    components: {
      ClickToCallMain,
      NotificationFlowModal
    },
    mixins: [FormMixin],
    methods: {
      isFullyAuthenticated() {
        // This corresponds to a check in SAM for the user's required authentication type to be `PortalAuthenticationRequirementType.NONE`.
        // Otherwise the displayed modal message on Portal may not match the required authentication type on SAM.
        return this.$root.authStatus.authTypeRequired === 'NONE';
      },
      setupRouteForShowTask(leaveId, taskId) {
        const route = this.$root.getRouteByName('todo');
        route.query = { 'defaultAction': 'showTask', 'taskId': taskId };
        this.intendedRoute = route;
      },
      setupAuthenticatedShowTaskModal(leaveId, taskId) {
        this.dialogClass = "login-required-flow";
        this.dialogIconSrc = null;
        this.dialogHeading = "Next Step:";
        this.dialogAction = this.handleSetIntendedRouteAndTriggerAuthStatusUpdate;
      },
      setupUnauthenticatedShowTaskModal(leaveId, taskId) {
        this.dialogClass = "login-required-flow";
        this.dialogIconSrc = null;
        this.dialogHeading = "We've sent you a validation code";
        this.dialogAction = this.handleSetIntendedRouteAndTriggerAuthStatusUpdate;
      },
      setupFitnessForDuty(leaveId, taskId) {
        this.setupRouteForShowTask(leaveId, taskId);
        if (!this.isFullyAuthenticated()) {
          this.getAuthMethodAndUpdateDialogInstruction(this.responseAuthStatus.portalUserId);
          this.setupUnauthenticatedShowTaskModal(leaveId, taskId);
        } else {
          this.setupAuthenticatedShowTaskModal(leaveId, taskId);
          this.dialogInstruction = "Continue to the portal and complete the Fitness for Duty Certificate.";
        }
        this.dialogActionText = "Access Fitness for Duty Certificate";
      },
      setupLeaveCertification(leaveId, taskId) {
        this.setupRouteForShowTask(leaveId, taskId);
        if (!this.isFullyAuthenticated()) {
          this.getAuthMethodAndUpdateDialogInstruction(this.responseAuthStatus.portalUserId);
          this.setupUnauthenticatedShowTaskModal(leaveId, taskId);
        } else {
          this.dialogInstruction = "Continue to the portal and complete the Leave Certification.";
          this.setupAuthenticatedShowTaskModal(leaveId, taskId);
        }
        this.dialogActionText = "Access Leave Certification";
      },
      showModal() {
        if (!this.dialog) {
          console.error("No dialog");
          return;
        }

        this.dialog.showModal();
      },
      closeModal() {
        if (!this.dialog) {
          console.error("No dialog");
          return;
        }

        this.dialog.close();
      },
      handleBackToPortalLogin() {
        this.closeModal();
        this.$router.push({name: this.$constants.LoginRouteNames.EMPLOYEE});
      },
      handleSetIntendedRouteAndTriggerAuthStatusUpdate() {
        this.closeModal();
        this.$root.intendedRoute = this.intendedRoute;

        const shouldNavigateToDefaultRoute = this.$root.authStatus.portalUserId === this.responseAuthStatus.portalUserId;
        this.$root.updateAuthStatus()
          .then(response => {
            if (shouldNavigateToDefaultRoute) {
              this.$root.navigateToDefaultRoute();
            }
          });
      },
      exchangeToken() {
        this.submit('post', ApiProperties.api.validateEmailToPortalToken, {}, {
            token: this.$route.query.token
          },
          (response) => {
            this.success = true;
            const authenticationStatus = response.data.authenticationStatus;
            this.responseAuthStatus = authenticationStatus;
            const emailToPortalFlow = response.data.emailToPortalFlow;
            switch (emailToPortalFlow.type) {
              case "FITNESS_FOR_DUTY": {
                const leaveId = emailToPortalFlow.leaveId;
                const taskId = emailToPortalFlow.taskId;
                this.setupFitnessForDuty(leaveId, taskId);
                break;
              }
              case "LEAVE_CERTIFICATION": {
                const leaveId = emailToPortalFlow.leaveId;
                const taskId = emailToPortalFlow.taskId;
                this.setupLeaveCertification(leaveId, taskId);
                break;
              }
              default:
                console.log(`Unrecognized flow: ${emailToPortalFlow.type}`);
                break;
            }

            this.showModal();
          }, () => {
            this.showModal();
          });
      },
      getAuthMethodAndUpdateDialogInstruction(portalUserId) {
        const pathParams = { pathVars: { portalUserId: portalUserId } };
        this.$http.get(ApiProperties.api.user, pathParams)
          .then(response => {
            const user = response.data;

            if (user.authMethod === 'SMS' && user.phoneLastFourDigits) {
              this.dialogInstruction = `Validation code sent to the phone number ending in ${user.phoneLastFourDigits}.`;
            } else if (user.authMethod === 'EMAIL' && user.emailAddress) {
              this.dialogInstruction = `Validation code sent to your email address.`;
            }

            this.dialogInstruction += '\nClick below to log in with your validation code.';
          })
          .catch((error) => {
            console.log(error)
          });
      }
    },
    mounted: function() {
      this.dialog = this.$refs.notificationFlowModal;
      this.exchangeToken();
    }
  }
</script>

<style scoped>
  @media only screen and (max-width: 575.98px) {
    .root {
      padding: 0;
    }
  }
</style>
