<template>
  <dialog ref="dialog" :class="dialogClass">
    <div class="dialog-title px-4 py-0">
      <img class="logo" alt="Larkin" src="/img/logo/larkin-logo-black.svg" />
    </div>
    <div v-if="formError" class="dialog-body p-4 invalid-feedback">
      <p v-if="formErrorMessage" class="text-danger">{{formErrorMessage}}</p>
      <p v-else class="text-danger">Sorry, but we were unable to process your response. Please contact The Larkin Company for assistance.</p>
    </div>
    <div v-else class="dialog-body p-4">
      <img v-if="dialogIconSrc" class="check" alt="Check mark" :src="dialogIconSrc" />
      <h3>{{dialogHeading}}</h3>
      <p v-if="dialogInstruction !== ''">{{dialogInstruction}}</p>
    </div>
    <div v-if="formError" class="dialog-actions pb-4 pt-0 px-4">
      <div v-on:click="handleBackToPortalLogin()" class="dialog-action">Back to Larkin Portal</div>
    </div>
    <div v-else class="dialog-actions pb-4 pt-0 px-4">
      <div v-on:click="dialogAction()" class="dialog-action">{{dialogActionText}}</div>
    </div>
  </dialog>
</template>

<script>
  export default {
    name: 'NotificationFlowModal',
    components: {},
    mixins: [],
    data: function() {
      return {
        dialog: null
      }
    },
    methods: {
      close() {
        this.dialog.close();
      },
      dialogAction() {
        this.$emit('dialogAction');
      },
      handleBackToPortalLogin() {
        this.$emit('handleBackToPortalLogin');
      },
      showModal() {
        this.dialog.showModal();
      }
    },
    props: {
      dialogClass: String,
      dialogHeading: String,
      dialogIconSrc: String,
      dialogInstruction: String,
      dialogActionText: String,
      formError: Object,
      formErrorMessage: String
    },
    mounted: function() {
      this.dialog = this.$refs.dialog;
    }
  };
</script>

<style scoped>
  dialog {
    padding: 0;
    width: 420px;
    border: none;
    background-color: #F7F5F2;

    &::backdrop {
      background-color: #ECE7E0;
    }
  }

  .dialog-title {
    display: flex;
    align-items: center;
    min-height: 50px;
    line-height: 50px;
    border-bottom: solid 1px #D8CEC0;
  }

  .dialog-title img.logo {
    height: 20px;
  }

  .dialog-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: #0E1F22;

    /* Desktop/Body M */
    font-family: Apercu Pro;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem; /* 150% */

    & h3 {
      font-size: 24px;
      line-height: 28.8px;
    }
  }

  .login-required-flow > .dialog-body > h3 {
    text-align: left;
  }

  .terminal-flow > .dialog-body > h3 {
    text-align: center;
    margin-bottom: 32px;
  }

  .dialog-body > img.check {
    height: 44px;
  }

  .dialog-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 10px;
  }

  .dialog-action {
    cursor: pointer;
    background-color: #FFD600;
    color: #0E1F22;
    flex-grow: 4;
    text-align: center;
    font-feature-settings: 'dlig' on;
    font-family: Apercu Pro;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;

    &:hover {
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 575.98px) {
    dialog {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
</style>
