/**
 * Form Mixin - Larkin Custom Form Mixin
 *
 * This give some helper methods to handle the submit of forms.
 *
 * 1. this.submitted: on form submit, this is true
 *
 *
 */

export default {
  data: function () {
    return {
      lastErrorResponse: null,
      submitted: false
    }
  },

  methods: {
    formError() {
      return this.fieldError();
    },

    formErrorMessage() {
      const formError = this.formError();
      return formError ? formError.message : '';
    },

    fieldError(fieldName) {
      if (!this.lastErrorResponse) {
        return;
      }

      if (!fieldName) {
        return this.lastErrorResponse.data;
      }

      return this.lastErrorResponse.data && this.lastErrorResponse.data.subErrors ? this.lastErrorResponse.data.subErrors[fieldName] : null;
    },

    clearErrors() {
      this.lastErrorResponse = null;
    },

    submit(method, url, config, data, success, error) {
      this.submitted = true;

      this.clearErrors();

      // "this" refers to the parent vue object including the formMixin and the "$v" is the vuelidate validations object
      if (this.$v && this.$v.$invalid) {
        return;
      }

      this.$http[method](url, data, config)
        .then(response => {
          return success ? success(response) : response;
        })
        .catch(e => {
          this.lastErrorResponse = e.response;

          if (error) error(e);
        });
    },

    getInvalidFeedbackClass: function(isInvalidField) {
      return ((this.submitted && isInvalidField)) ? 'custom-invalid-feedback' : '';
    }
  }
}